// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/logo_white.png", import.meta.url);
// Module
var code = `<template>
  <require from="./users.scss"></require>
  <require from="../../../../components/loader-ring/loader-ring"></require>

  <section id="agreements_users" class="flex-column">
    <div id="header" class="flex-row">
      <h1 i18n="bank.agreement.agreements_users1"></h1>
    </div>
    <div class="flex-grow"></div>
    <div class="paragraph_container flex-column">
      <div class="logo_and_bank flex-row">
        <div class="blackground">
          <img src="${___HTML_LOADER_IMPORT_0___}" />
        </div>
        <span>X</span>
        <div class="blackground">
          <img id="bank_img" src.bind="state.selectedBankToAdd.media[0].source" alt="" />
        </div>
      </div>
      <div class="flex-grow"></div>
      <div class="all-paragraphs">
        <div class="paragraph">
          <h2 i18n="bank.agreement.agreements_users1"></h2>
          <span class="gray" i18n="bank.agreement.agreements_users2"></span>
          <span class="gray" i18n="bank.agreement.agreements_users_yapily"></span>
        </div>
        <div class="flex-grow"></div>
        <div class="paragraph">
          <h2 i18n="bank.agreement.agreements_users3"></h2>
          <span class="gray" i18n="bank.agreement.agreements_users4"></span>
        </div>
        <div class="flex-grow"></div>
        <div class="paragraph" if.bind="!isError">
          <h2 i18n="bank.agreement.agreements_users5"></h2>
          <span class="gray" i18n="bank.agreement.agreements_users6"></span>
        </div>
      </div>
    </div>

    <div class="flex-grow"></div>
    <p class="red" if.bind="isError">\${errorMessage}</p>
    <div class="flex-grow" if.bind="isError"></div>
    <span i18n="bank.agreement.agreements_users_accept_banka_conditions" class="header"></span>
    <div class="labels">
      <label class="container" class.bind="shaking ? 'shake' : ''" style="padding-left: -1rem;">
        <p style="margin:0px; font-weight: 500; white-space: nowrap;">
          <span click.delegate="router.navigateToRoute('terms')" class="white" i18n="bank.agreement.agreements_users10"></span>
        </p>      
        <input type="checkbox" checked.bind="isOurConditionsAccepted" />
        <span class="checkmark"></span>
      </label>
      <label class="container" class.bind="shaking ? 'shake' : ''">
        <p style="margin:0px; font-weight: 500; white-space: nowrap;">
          <span onclick="window.location = 'https://www.yapily.com/legal/yapilyconnect-terms-and-conditions/'" class="white" i18n="bank.agreement.agreements_users11"></span>
        </p>
        <input type="checkbox" checked.bind="isYapilyConditionsAccepted" />
        <span class="checkmark"></span>
      </label>
    </div>

    <div class="flex-grow"></div>
    <button
      class="btn btn-primary fakeDisabled"
      if.bind="!isOurConditionsAccepted || !isYapilyConditionsAccepted"
      click.delegate="shakeError()"
    >
      <span i18n="bank.agreement.agreements_users12"></span>
    </button>
    <button
      class="btn btn-primary"
      if.bind="isYapilyConditionsAccepted && isOurConditionsAccepted"
      click.delegate="makeAgreement()"
      disabled.bind="isLoading"
    >
      <span i18n="bank.agreement.agreements_users12" if.bind="!isLoading"></span>
      <loader-ring class="loader-btn" if.bind="isLoading"></loader-ring>
    </button>
  </section>
</template>
`;
// Exports
export default code;